import React from "react";
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from '../components/PagesHeader';
import { styled } from "@mui/system";

// Image assets
import cap1 from "../assets/categories/caps/cap1.jpeg";
import cap2 from "../assets/categories/caps/cap2.jpeg";
import cap3 from "../assets/categories/caps/cap3.jpeg";
import Footer from "../components/Footer";

const products = [
  { id: 1, name: "White Cap", image: cap1, price: "NGN 9,999.00" },
  { id: 2, name: "Snapback (Red)", image: cap2, price: "NGN 9,999.00" },
  { id: 3, name: "Premium Cap (Black)", image: cap3, price: "NGN 18,300.00" },
];

// Styled components
const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#6e45e2",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "200px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  transition: "background-image 0.3s ease",
});

const OrderButton = styled(Button)({
  backgroundColor: "#6e45e2",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#5c3ec6",
    transform: "scale(1.05)",
  },
});

const Caps = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="lg" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            YEMIDYN Caps
          </Typography>
          <HighlightedText variant="h5">Stay Cool, Stay Classic</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            Complete your look with our range of premium caps, designed to keep you shaded and stylish. Ideal for all-day comfort and adding a touch of casual flair to any outfit.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Breathable fabric for all-day comfort</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Adjustable fit to suit all sizes</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Durable and stylish design</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Available in classic colors and styles</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Grid */}
        <Grid container spacing={3}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <StyledCard>
                <ProductImageWrapper
                  component="img"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent style={{ textAlign: "center", padding: "12px" }}>
                  <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
                    {product.name}
                  </Typography>
                  {/* Price */}
                  <Typography variant="h5" color="text.secondary" style={{ marginBottom: "8px" }}>
                    {product.price}
                  </Typography>
                  <OrderButton
                    variant="contained"
                    endIcon={<ShoppingCartIcon />}
                    fullWidth
                    href="https://wa.me/c/2348169469300"
                    target="_blank"
                  >
                    Order
                  </OrderButton>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Caps;
