import React from "react";
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from '../components/PagesHeader';
import { styled } from "@mui/system";
import Footer from "../components/Footer";

// Image asset
import singlet from "../assets/categories/singlet/singlet.jpeg";

// Styled components

const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#4a4a4a",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "300px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  transition: "background-image 0.3s ease",
});

const OrderButton = styled(Button)({
  backgroundColor: "#4a4a4a",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#333",
    transform: "scale(1.05)",
  },
});

const Singlet = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="md" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            Premium Singlet
          </Typography>
          <HighlightedText variant="h5">Effortless Comfort and Style</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            This premium singlet offers unparalleled comfort and breathability, ideal for workouts or casual wear. Its sleek design ensures you stay stylish and comfortable all day long.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Soft and breathable fabric</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Slim fit design for modern style</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Perfect for both sports and leisure</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Display */}
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <StyledCard>
              <ProductImageWrapper
                component="img"
                image={singlet}
                alt="Premium Singlet"
              />
              <CardContent style={{ textAlign: "center", padding: "12px" }}>
                <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
                  Premium Singlet
                </Typography>
                {/* Price */}
                <Typography variant="h5" color="secondary" style={{ marginBottom: "8px" }}>
                  NGN 14,999.00
                </Typography>
                <OrderButton
                  variant="contained"
                  endIcon={<ShoppingCartIcon />}
                  fullWidth
                  href="https://wa.me/c/2348169469300"
                  target="_blank"
                >
                  Order
                </OrderButton>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Singlet;
