import React from "react";
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from '../components/PagesHeader';
import { styled } from "@mui/system";
import Footer from "../components/Footer";

// Image assets
import cropTop from "../assets/categories/ladies/cropTop.jpeg";
import shortSkirt from "../assets/categories/ladies/shortSkirt.jpeg";

const products = [
  { id: 1, name: "Crop Top", image: cropTop, price: "NGN 24,999.00" },
  { id: 2, name: "Short Skirt", image: shortSkirt, price: "NGN 29,999.00" },
];

// Styled components
const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#e91e63",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "250px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  transition: "background-image 0.3s ease",
});

const OrderButton = styled(Button)({
  backgroundColor: "#e91e63",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#d81b60",
    transform: "scale(1.05)",
  },
});

const Ladies = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="lg" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            YEMIDYN Ladies Collection
          </Typography>
          <HighlightedText variant="h5">Effortless Style, Ultimate Comfort</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            Step out in confidence with our exclusive ladies' collection. From casual wear to statement pieces, each item is crafted with comfort and style in mind to elevate your look for any occasion.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Premium fabrics for a soft touch</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Tailored for a flattering fit</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Perfect for casual and evening wear</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Grid */}
        <Grid container spacing={3}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={6} key={product.id}>
              <StyledCard>
                <ProductImageWrapper
                  component="img"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent style={{ textAlign: "center", padding: "12px" }}>
                  <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
                    {product.name}
                  </Typography>
                  {/* Price */}
                  <Typography variant="h5" color="secondary" style={{ marginBottom: "8px" }}>
                    {product.price}
                  </Typography>
                  <OrderButton
                    variant="contained"
                    endIcon={<ShoppingCartIcon />}
                    fullWidth
                    href="https://wa.me/c/2348169469300"
                    target="_blank"
                  >
                    Order
                  </OrderButton>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Ladies;
