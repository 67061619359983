// Testimonials.js
import React from "react";
import { Card, CardContent, Typography, Grid, Avatar } from "@mui/material";
import david from "../assets/testimonials/david.jpg";
import jane from "../assets/testimonials/jane.jpg";
import john from "../assets/testimonials/john.jpg";
import jude from "../assets/testimonials/jude.jpg";
import special from "../assets/testimonials/special.jpg";
import benzema from "../assets/testimonials/benzema.jpeg";

const testimonialsData = [
  {
    name: "Boller Muller",
    comment: "Love your Brand❤️🦅!",
    image: david,
  },
  {
    name: "The King Simi",
    comment:
      "I definately love the brand, it gives a feeling of comfortability and the quality is to die for.",
    image: jane,
  },
  {
    name: "Dj Dopex",
    comment:
      "Yemidyn is a standout clothing brand known for its commitment to quality and transparency.",
    image: john,
  },
  {
    name: "Silent",
    comment:
      "Yemidyn has one of the best dresses of all times. I love the style and pattern. It is the best for me.",
    image: jude,
  },
  {
    name: "Special BTC",
    comment:
      "Yemidyn is the best brand i've ever used! Their products are exceptional and have exceeded my expectations. I'm a loyal customer for life!❤️.",
    image: special,
  },
  {
    name: "Benzema",
    comment:
      "You're going global🌐.",
    image: benzema,
  },
];

const Testimonials = () => {
  return (
    <div
      id="testimonials"
      style={{ padding: "2rem 0", backgroundColor: "#f4f4f4" }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Testimonials
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {testimonialsData.map((testimonial, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card style={{ maxWidth: 345, margin: "auto" }}>
              <Avatar
                alt={testimonial.name}
                src={testimonial.image}
                style={{
                  width: 60,
                  height: 60,
                  margin: "auto",
                  marginTop: "1rem",
                }}
              />
              <CardContent>
                <Typography variant="h6" align="center">
                  {testimonial.name}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  {testimonial.comment}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Testimonials;
