import React from "react"; 
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from '../components/PagesHeader';
import Footer from "../components/Footer";
import { styled } from "@mui/system";

// Image asset for the single sweatband product
import sweatbands from "../assets/categories/sweatbands/sweatbands.jpeg";

// Styled components
const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#6e45e2",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "300px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  transition: "background-image 0.3s ease",
});

const OrderButton = styled(Button)({
  backgroundColor: "#6e45e2",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#5c3ec6",
    transform: "scale(1.05)",
  },
});

const Sweatbands = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="md" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            YEMIDYN Sweatband
          </Typography>
          <HighlightedText variant="h5">Peak Performance, Maximum Comfort</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            Designed for athletes and active individuals, the YEMIDYN sweatband is crafted to keep sweat out of your eyes while providing a comfortable, snug fit. Whether you're hitting the gym, running a marathon, or just enjoying a sunny day, this sweatband has you covered.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Ultra-absorbent material</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Comfortable, flexible fit for all sizes</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Lightweight and breathable</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Durable for intense workouts</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Display */}
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <StyledCard>
              <ProductImageWrapper
                component="img"
                image={sweatbands}
                alt="YEMIDYN Sweatband"
              />
              <CardContent style={{ textAlign: "center", padding: "12px" }}>
                <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
                  YEMIDYN Premium Sweatband
                </Typography>
                {/* Price */}
                <Typography variant="h5" color="text.secondary" style={{ marginBottom: "8px" }}>
                NGN 19,999.00
                </Typography>
                <OrderButton
                  variant="contained"
                  endIcon={<ShoppingCartIcon />}
                  fullWidth
                  href="https://wa.me/p/7507796122632315/2348169469300"
                  target="_blank"
                >
                  Order Now
                </OrderButton>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Sweatbands;
