import React from "react";
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from "../components/PagesHeader";
import { styled } from "@mui/system";
import Footer from "../components/Footer";

// Image assets
import hoodie1 from "../assets/categories/hoodies/hoodie1.jpeg";
import hoodie2 from "../assets/categories/hoodies/hoodie2.jpeg";
import hoodie3 from "../assets/categories/hoodies/hoodie3.jpeg";
import hoodie4 from "../assets/categories/hoodies/hoodie4.jpeg";

const products = [
  { id: 1, name: "Yellow Hoodie", price: "NGN 34,999.00", image: hoodie1 },
  { id: 2, name: "Black Hoodie", price: "NGN 34,999.00", image: hoodie2 },
  { id: 3, name: "Red Hoodie", price: "NGN 34,999.00", image: hoodie3 },
  { id: 4, name: "White Hoodie", price: "NGN 34,999.00", image: hoodie4 },
];

// Styled components
const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#6e45e2",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "240px",
  backgroundPosition: "center",
  backgroundSize: "cover",
});

const OrderButton = styled(Button)({
  backgroundColor: "#6e45e2",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#5c3ec6",
    transform: "scale(1.05)",
  },
});

const Hoodies = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="lg" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            YEMIDYN Hoodies
          </Typography>
          <HighlightedText variant="h5">Stay Warm, Stay Stylish</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            Embrace comfort and style with our premium hoodies, designed to keep you warm while looking effortlessly cool.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Soft, fleece-lined interior</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Relaxed fit for added comfort</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Durable, high-quality fabric</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Available in classic colors</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Ribbed cuffs and hem for a snug fit</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Grid */}
        <Grid container spacing={3}>
          {products.map((product) => (
            <Grid item xs={6} sm={4} md={3} key={product.id}>
              <StyledCard>
                <ProductImageWrapper
                  component="img"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent style={{ textAlign: "center", padding: "12px" }}>
                  <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
                    {product.name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    {product.price}
                  </Typography>
                  <OrderButton
                    variant="contained"
                    endIcon={<ShoppingCartIcon />}
                    fullWidth
                    href="https://wa.me/c/2348169469300"
                    target="_blank"
                  >
                    Order
                  </OrderButton>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Hoodies;
