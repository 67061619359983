import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import aboutImage from "../assets/aboutt.jpeg"; // Replace with the actual image path

const AboutUs = () => {
  return (
    <section id="about">
      <Box
        id="about-us"
        sx={{
          padding: "50px 20px",
          textAlign: "center",
          backgroundColor: "#eaeaea",
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={aboutImage}
              alt="About Us"
              sx={{ borderRadius: "12px", width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              About Us
            </Typography>
            <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
              We are a leading fashion brand that redefines elegance and style.
              Our collections are crafted to provide timeless beauty with a
              touch of modern flair. Join us on our journey as we explore the
              intersection of fashion and culture.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default AboutUs;
