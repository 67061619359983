import React, { useState } from "react";
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from '../components/PagesHeader';
import { styled } from "@mui/system";

// Image assets
import tshirt1 from "../assets/categories/tshirt/tshirt1.jpeg";
import tshirt2 from "../assets/categories/tshirt/tshirt2.jpeg";
import tshirt3 from "../assets/categories/tshirt/tshirt3.jpeg";
import tshirt4 from "../assets/categories/tshirt/tshirt4.jpeg";
import tshirt5 from "../assets/categories/tshirt/tshirt5.jpeg";
import tshirt6 from "../assets/categories/tshirt/tshirt6.jpeg";
import tshirt7 from "../assets/categories/tshirt/tshirt7.jpeg";
import tshirt8 from "../assets/categories/tshirt/tshirt8.jpeg";
import tshirt9 from "../assets/categories/tshirt/tshirt9.jpeg";
import tshirt10 from "../assets/categories/tshirt/tshirt10.jpeg";
import Footer from "../components/Footer";

const products = [
  { id: 1, name: "Blue T-shirt", images: [tshirt1, tshirt2], price: "NGN 19,999.00" },
  { id: 2, name: "Red T-shirt", images: [tshirt3, tshirt4], price: "NGN 19,999.00" },
  { id: 3, name: "Pink T-shirt", images: [tshirt5, tshirt6], price: "NGN 19,999.00" },
  { id: 4, name: "White T-shirt", images: [tshirt7, tshirt8], price: "NGN 19,999.00" },
  { id: 5, name: "Yellow T-shirt", images: [tshirt9, tshirt10], price: "NGN 19,999.00" },
];

// Styled components
const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#6e45e2",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "220px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  transition: "background-image 0.3s ease",
});

const OrderButton = styled(Button)({
  backgroundColor: "#6e45e2",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#5c3ec6",
    transform: "scale(1.05)",
  },
});

const Tshirts = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="lg" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            YEMIDYN Premium Tees
          </Typography>
          <HighlightedText variant="h5">Stay Comfortable, Stay Stylish</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            Elevate your everyday look with our premium tees, crafted from ultra-soft material for unparalleled comfort. Perfect for casual outings, workouts, or lounging around.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Soft, breathable material</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Relaxed fit for a stylish vibe</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> High-quality printing</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Durable stitching</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Available in various sizes and colors</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Grid */}
        <Grid container spacing={3}>
          {products.map((product) => (
            <Grid item xs={6} sm={4} md={3} key={product.id}>
              <HoverableCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

// HoverableCard Component to manage front/back toggle on hover
const HoverableCard = ({ product }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <StyledCard
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <ProductImageWrapper
        image={isFlipped ? product.images[1] : product.images[0]}
        alt={product.name}
      />
      <CardContent style={{ textAlign: "center", padding: "12px" }}>
        <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
          {product.name}
        </Typography>
        <Typography variant="body1" color="textSecondary" style={{ fontWeight: "500" }}>
          {product.price}
        </Typography>
        <OrderButton
          variant="contained"
          endIcon={<ShoppingCartIcon />}
          fullWidth
          href="https://wa.me/c/2348169469300"
          target="_blank"
        >
          Order
        </OrderButton>
      </CardContent>
    </StyledCard>
  );
};

export default Tshirts;
