import React from "react";
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PagesHeader from '../components/PagesHeader';
import { styled } from "@mui/system";
import Footer from "../components/Footer";

// Image assets
import cropTeeExploit from "../assets/categories/croptee/cropTeeExploit.jpeg";
import cropTeeBeyondLimit from "../assets/categories/croptee/cropTeeBeyondLimit.jpeg";
import cropTeeHot from "../assets/categories/croptee/cropTeeHot.jpeg";
import cropTeeCosmos from "../assets/categories/croptee/cropTeeCosmos.jpeg";

const products = [
  { id: 1, name: "Crop Tee (EXPLOIT)", image: cropTeeExploit, price: "NGN 34,999.00" },
  { id: 2, name: "Crop Tee (BEYOND LIMIT)", image: cropTeeBeyondLimit, price: "NGN 34,999.00" },
  { id: 3, name: "Crop Tee (HOT)", image: cropTeeHot, price: "NGN 34,999.00" },
  { id: 4, name: "Crop Tee (COSMOS)", image: cropTeeCosmos, price: "NGN 34,999.00" },
];

// Styled components
const DescriptionSection = styled(Box)({
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f8",
  borderRadius: "12px",
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  marginBottom: "20px",
});

const HighlightedText = styled(Typography)({
  display: "inline-block",
  color: "#ff4081",
  fontWeight: "bold",
  fontSize: "1.1em",
  margin: "8px 0",
});

const StyledCard = styled(Card)({
  borderRadius: "12px",
  overflow: "hidden",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.04)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
  },
});

const ProductImageWrapper = styled(CardMedia)({
  height: "250px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  transition: "background-image 0.3s ease",
});

const OrderButton = styled(Button)({
  backgroundColor: "#ff4081",
  color: "#fff",
  fontWeight: "500",
  fontSize: "0.9em",
  padding: "8px 16px",
  borderRadius: "20px",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    backgroundColor: "#f50057",
    transform: "scale(1.05)",
  },
});

const CropTee = () => {
  return (
    <>
      <PagesHeader />
      <Container maxWidth="lg" style={{ marginTop: "20px", marginBottom: "40px" }}>
        {/* Page Description */}
        <DescriptionSection>
          <Typography variant="h3" gutterBottom style={{ fontWeight: "600", color: "#333" }}>
            YEMIDYN Crop Tee Collection
          </Typography>
          <HighlightedText variant="h5">Style Meets Comfort</HighlightedText>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "12px", maxWidth: "700px", margin: "0 auto" }}>
            Elevate your look with our exclusive crop tees. Designed for bold individuals, each piece adds a unique touch to your wardrobe, perfect for casual outings or making a statement.
          </Typography>
          <Box style={{ marginTop: "20px", textAlign: "center", fontSize: "0.95em" }}>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Soft, breathable fabric for all-day comfort</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Versatile designs for a trendy look</Typography>
            <Typography variant="body2"><CheckCircleIcon fontSize="small" color="primary" /> Perfect for layering or solo wear</Typography>
          </Box>
        </DescriptionSection>

        {/* Product Grid */}
        <Grid container spacing={3}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={3} key={product.id}>
              <StyledCard>
                <ProductImageWrapper
                  component="img"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent style={{ textAlign: "center", padding: "12px" }}>
                  <Typography variant="h6" fontWeight="600" color="text.primary" gutterBottom>
                    {product.name}
                  </Typography>
                  {/* Price */}
                  <Typography variant="h5" color="secondary" style={{ marginBottom: "8px" }}>
                    {product.price}
                  </Typography>
                  <OrderButton
                    variant="contained"
                    endIcon={<ShoppingCartIcon />}
                    fullWidth
                    href="https://wa.me/c/2348169469300"
                    target="_blank"
                  >
                    Order
                  </OrderButton>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default CropTee;
