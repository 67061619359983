import React from "react";
import { Typography, Box, Container, CardMedia, Button } from "@mui/material";
import { styled } from "@mui/system";
import Carousel from "react-material-ui-carousel";

// Import images for the latest collection
import baggyJeans from "../assets/latest/baggy-jeans.jpeg";
import cropTop from "../assets/latest/crop-top.jpeg";
import classicJeans from "../assets/latest/classic-jeans.jpeg";
import cropTee from "../assets/latest/crop-tee.jpeg";

// Latest collection data
const latestCollections = [
  {
    title: "Baggy Jeans Shorts (Jorts)",
    image: baggyJeans,
  },
  {
    title: "Crop Top/Short Shirt",
    image: cropTop,
  },
  {
    title: "Classic Jeans",
    image: classicJeans,
  },
  {
    title: "Crop Tee (HOT)",
    image: cropTee,
  },
];

// Styled components
const CollectionContainer = styled(Container)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(4, 2), // Adds padding on smaller screens
}));

const CollectionCard = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  maxWidth: "550px",
  maxHeight: "750px",
  aspectRatio: "4/5",
  margin: "0 auto",
  padding: theme.spacing(2), // Add padding on mobile
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%", // Narrow the width on small screens
    borderRadius: "8px",
  },
}));

const CollectionImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  filter: "brightness(0.85)",
  transition: "transform 0.3s ease", // Smooth transition for images
  "&:hover": {
    transform: "scale(1.02)", // Slight zoom effect on hover
  },
  [theme.breakpoints.down("sm")]: {
    objectFit: "cover",
    height: "50vh", // Reduces height on mobile
  },
}));

const CollectionInfo = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "16px",
  left: "16px",
  color: "#fff",
  textAlign: "left",
  padding: theme.spacing(1),
}));

const ShopLatestButton = styled(Button)({
  backgroundColor: "#6e45e2",
  color: "#fff",
  fontWeight: "500",
  borderRadius: "20px",
  padding: "10px 20px",
  marginTop: "20px",
  "&:hover": {
    backgroundColor: "#5c3ec6",
  },
});

const LatestCollectionsSection = () => (
  <CollectionContainer>
    <Typography variant="h5" component="h3" gutterBottom>
      Latest Collections
    </Typography>
    <Typography variant="body1" color="textSecondary" mb={4}>
      Discover our hottest additions – crafted for those who set the trend.
    </Typography>

    {/* Carousel for latest collection items */}
    <Carousel
      interval={5000}
      indicators={false}
      animation="fade" // Set a smooth fade transition for slides
      navButtonsAlwaysVisible // Hides nav buttons for a cleaner look
    >
      {latestCollections.map((item, index) => (
        <CollectionCard key={index}>
          <CollectionImage component="img" image={item.image} alt={item.title} />
          <CollectionInfo>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {item.title}
            </Typography>
            <Typography variant="body2">{item.description}</Typography>
          </CollectionInfo>
        </CollectionCard>
      ))}
    </Carousel>

    {/* Shop Latest Button */}
    <ShopLatestButton
      variant="contained"
      href="https://wa.me/2348169469300"
      target="_blank"
    >
      Shop Latest
    </ShopLatestButton>
  </CollectionContainer>
);

export default LatestCollectionsSection;
