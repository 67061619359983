// ProductShowcase.js
import React from "react";
import { Typography, Box, Container, Grid, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";

// Import images
import caps from "../assets/products/caps.jpg";
import hoodies from "../assets/products/hoodies.jpg";
import tshirts from "../assets/products/tshirts.jpg";
import sweatbands from "../assets/products/sweatbands.jpg";
import socks from "../assets/products/socks.jpg";
import comingSoon from "../assets/products/coming-soon.jpg";
import jeans from "../assets/latest/classic-jeans.jpeg";
import ladies from "../assets/categories/ladies/shortSkirt.jpeg";
import croptee from "../assets/categories/croptee/cropTeeCosmos.jpeg";
import hat from "../assets/categories/hat/Hat.jpeg";
import singlet from "../assets/categories/singlet/singlet.jpeg";

// Define product data
const products = [
  { id: 1, title: "Caps", image: caps },
  { id: 2, title: "Hoodies", image: hoodies },
  { id: 3, title: "T-Shirts", image: tshirts },
  { id: 4, title: "Sweatbands", image: sweatbands },
  { id: 5, title: "Socks", image: socks },
  { id: 6, title: "Sweatshirts", image: comingSoon },
  { id: 7, title: "Jeans", image: jeans },
  { id: 8, title: "Ladies-wears", image: ladies },
  { id: 9, title: "Crop-Tee", image: croptee },
  { id: 10, title: "Rugged-Hat", image: hat },
  { id: 11, title: "Singlet", image: singlet },
];

// Styled components
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  backgroundColor: "#f9f9f9",
}));


const ProductContainer = styled(Grid)({
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
});

const ProductCard = styled(motion.div)({
  position: "relative",
  overflow: "hidden",
  borderRadius: "8px",
  cursor: "pointer",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
});

const ProductImage = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  filter: "brightness(1.0)",
});

const ProductOverlay = styled(Box)({
  position: "absolute",
  inset: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  fontSize: "1.3rem",
  fontWeight: 500,
  zIndex: 1,
  textAlign: "center",
});

const ArrowIconWrapper = styled(IconButton)({
  position: "absolute",
  bottom: 12,
  right: 12,
  color: "#fff",
  zIndex: 2,
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
});

const WhyChooseUsSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  backgroundColor: "#eaeaea",
  textAlign: "center",
}));

const ProductShowcase = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Product Showcase Section */}
      <Section id="products">
        <Container maxWidth="lg" sx={{ mt: 0 }} >
          <Typography variant="h4" component="h2" align="center" gutterBottom>
            Shop by Category
          </Typography>
          <ProductContainer>
            {products.map((product) => (
              <ProductCard
                key={product.id}
                whileHover={{ scale: 1.05 }}
                onClick={() => navigate(`/${product.title.toLowerCase()}`)}
              >
                <ProductImage src={product.image} alt={product.title} />
                <ProductOverlay>{product.title}</ProductOverlay>
                <ArrowIconWrapper aria-label="view product details">
                  <ArrowForwardIcon />
                </ArrowIconWrapper>
              </ProductCard>
            ))}
          </ProductContainer>
        </Container>
      </Section>

      {/* Why Choose Us Section */}
      <WhyChooseUsSection>
        <Container maxWidth="md" sx={{ mt: 0 }} >
          <Typography variant="h5" component="h3" gutterBottom >
            Why Choose Us?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                icon: LocalShippingIcon,
                title: "Free Shipping",
                text: "All purchases over NGN50,000 are eligible for free shipping.",
              },
              {
                icon: PaymentIcon,
                title: "Easy Payments",
                text: "All payments are processed securely and instantly.",
              },
              {
                icon: VerifiedIcon,
                title: "Money-Back Guarantee",
                text: "Send it back for a full refund if you’ve changed your mind.",
              },
              {
                icon: StarIcon,
                title: "Finest Quality",
                text: "Crafted with the finest materials for lasting quality.",
              },
            ].map(({ icon: Icon, title, text }, idx) => (
              <Grid item xs={12} sm={6} md={3} key={idx}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Box textAlign="center" padding={2}>
                    <Icon color="primary" fontSize="large" />
                    <Typography variant="h6" mt={1}>
                      {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {text}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </WhyChooseUsSection>
    </>
  );
};

export default ProductShowcase;
