import { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png"; // Path to your logo image

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const categories = [
    "Caps",
    "Hoodies",
    "T-Shirts",
    "Sweatbands",
    "Socks",
    "Sweatshirts",
    "Jeans",
    "Ladies-wears",
    "Crop-Tee",
    "Rugged-Hat",
    "Singlet",
  ];

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (category) => {
    navigate(`/${category.toLowerCase()}`);
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "#fff", boxShadow: "none" }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton edge="start" color="black" aria-label="menu">
          <img src={Logo} alt="Yemidyn Logo" style={{ height: "40px" }} />
        </IconButton>

        <div>
          <Typography
            variant="h6"
            style={{
              display: "inline-block",
              cursor: "pointer",
              marginRight: 8,
              size: 2,
              color: "black",
              backgroundColor: "#f1f1f1",
              padding: "4px 8px",
              borderRadius: "6px", // Pill shape
            }}
            onClick={handleMenuClick}
          >
            Categories <ArrowDropDownIcon />
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {categories.map((category, idx) => (
              <MenuItem
                key={idx}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </MenuItem>
            ))}
          </Menu>

          <IconButton edge="end" color="black" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        </div>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          PaperProps={{
            style: {
              backgroundColor: "#000", // Background color of the drawer
              color: "#fff", // Text color in the drawer
            },
          }}
        >
          <div style={{ width: 250 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Typography variant="h6" style={{ color: "#fff" }}>
                Menu
              </Typography>
              <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: "#fff" }} />
            <List>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Typography component="a" href="#about" style={{ color: "#fff" }}>
                    About Us
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Typography component="a" href="#products" style={{ color: "#fff" }}>
                    Our Products
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Typography component="a" href="#testimonials" style={{ color: "#fff" }}>
                    Testimonials
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={handleDrawerClose}
                component="a"
                href="https://wa.me/c/2348169469300"
              >
                <ListItemText style={{ color: "#fff" }}>Order</ListItemText>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
