import { Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import Logo from "../assets/logo.png"; // Adjust the path to your logo image

const PagesHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCategoriesClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle menu open/close
  };

  const handleCategoriesClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px 20px",
        borderBottom: "2px solid #f0f0f5",
        backgroundColor: "#fff",
        position: "sticky",
        top: 0,
        zIndex: 1000,
      }}
    >
      {/* Logo with Link to Home */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.3 }}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <img src={Logo} alt="Yemidyn Logo" style={{ height: "40px", marginRight: "10px" }} />
      </motion.div>

      {/* Categories Dropdown - opens on click */}
      <div style={{ marginLeft: "auto", position: "relative" }}>
        <Typography
          onClick={handleCategoriesClick}
          style={{ cursor: "pointer", fontSize: "1rem", color: "#333", fontWeight: "500" }}
        >
          Categories
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCategoriesClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          PaperProps={{
            style: {
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "10px",
            },
          }}
        >
          <MenuItem onClick={() => { navigate("/caps"); handleCategoriesClose(); }}>Caps</MenuItem>
          <MenuItem onClick={() => { navigate("/hoodies"); handleCategoriesClose(); }}>Hoodies</MenuItem>
          <MenuItem onClick={() => { navigate("/t-shirts"); handleCategoriesClose(); }}>T-Shirts</MenuItem>
          <MenuItem onClick={() => { navigate("/sweatbands"); handleCategoriesClose(); }}>Sweatbands</MenuItem>
          <MenuItem onClick={() => { navigate("/socks"); handleCategoriesClose(); }}>Socks</MenuItem>
          <MenuItem onClick={() => { navigate("/sweatshirts"); handleCategoriesClose(); }}>Sweatshirts</MenuItem>
          <MenuItem onClick={() => { navigate("/jeans"); handleCategoriesClose(); }}>Jeans</MenuItem>
          <MenuItem onClick={() => { navigate("/ladies-wears"); handleCategoriesClose(); }}>LadiesWear</MenuItem>
          <MenuItem onClick={() => { navigate("/crop-tee"); handleCategoriesClose(); }}>CropTee</MenuItem>
          <MenuItem onClick={() => { navigate("/rugged-hat"); handleCategoriesClose(); }}>RuggedHat</MenuItem>
          <MenuItem onClick={() => { navigate("/singlet"); handleCategoriesClose(); }}>Singlet</MenuItem>
        </Menu>
      </div>

      {/* Home Link */}
      <Typography
        onClick={() => navigate("/")}
        style={{ marginLeft: "10px", cursor: "pointer", fontSize: "1rem", color: "#333", fontWeight: "500" }}
      >
        Home
      </Typography>
    </header>
  );
};

export default PagesHeader;
