import React from "react";
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import AboutUs from '../components/AboutUs';
import ProductShowcase from '../components/ProductShowcase';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import LatestCollectionsSection from '../components/LatestCollectionsSection';

const Home = () => {
  return (
    <div>
        <Header />
        <HeroSection />
        <AboutUs />
        <ProductShowcase />
        <LatestCollectionsSection />
        <Testimonials />
        <Footer />
    </div>
  );

}

export default Home;